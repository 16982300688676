class UseTouch {
  constructor() {
    this.lastY = 0
    this.currentY = 0
    this.moveStartY = 0
    this.startX = 0
    this.startY = 0
    this.isSwiperTouch = false
  }

  reset() {
    this.currentY = 0
    this.lastY = 0
    this.startX = 0
    this.startY = 0
    this.moveStartY = 0
  }

  touchStart(e) {
    this.reset()
    e.preventDefault()
    this.isSwiperTouch = this.isCateNewSwiperTouch(e)
    const { pageY, pageX } = this.getTouches(e)
    this.startY = pageY
    this.startX = pageX
    this.lastY = pageY
    this.startTimeStamp = e.timeStamp
  }

  /**
   * @description 移动
   * @param {Event} e 事件对象
   * */
  touchMove(e, cb) {
    e.preventDefault()
    
    const { pageX, pageY } = this.getTouches(e)
    this.currentY = pageY
    this.moveStartY = this.lastY
    const deltaY = pageY - this.lastY // 每次移动距离

    if (this.isSwiperTouch) {
      // swiper11滑动时，控制是否形成Y轴方向的位移
      if (this.isMoveToY({ startX: this.startX, endX: pageX, startY: this.startY, endY: pageY })) {
        cb({ deltaY })
      }
    } else {
      e.stopPropagation()
      cb({ deltaY })
    }
    
    this.lastY = pageY

    this.startMoveTime = this.endMoveTime
    this.endMoveTime = e.timeStamp
  }

  touchEnd(e, cb) {
    const { pageX, pageY } = this.getTouches(e)
    
    // swiper11滑动时，控制Y轴方向的弹动效果
    if (this.isSwiperTouch && !this.isMoveToY({ startX: this.startX, endX: pageX, startY: this.startY, endY: pageY })) {
      return
    }
    if (cb) {
      if (!this.endMoveTime) return // 没有滑动过

      let silenceTime = e.timeStamp - this.endMoveTime // 触摸持续时间
      if (silenceTime > 100) return // 停顿时间超过100ms不产生惯性滑动;
      let timeStamp = this.endMoveTime - this.startMoveTime // 快速过程时间
      timeStamp = timeStamp > 0 ? timeStamp : 8

      const speed = (this.lastY - this.moveStartY) / timeStamp // 初滑动速度

      cb({ speed })
    }
  }

  getIsMoveTop() {
    return this.currentY <= this.moveStartY
  }

  getTouches(e) {
    return e.targetTouches && (e.targetTouches[0] || e.changedTouches[0])
  }

  isCateNewSwiperTouch(e) {
    return !!e.target?.parentElement?.parentElement?.classList?.value?.includes?.('cate-swiper-img')
  }

  // 判断是否向Y轴抖动
  isMoveToY({ startX, startY, endX, endY }) {
    const movementX = Math.abs(endX - startX)
    const movementY = Math.abs(endY - startY)
    return movementY / movementX > 1
  }
  
}
export default UseTouch
